@import '../../../general/scss/settings/variables.scss';
@import '../../../general/scss/settings/mixins.scss';

.navbar {
    padding: 0 rem-calc(12);
    position: initial;
}

@include media-breakpoint-up(lg) {
    .navbar.collapse {
        display: block;
        height: auto;
        overflow: visible;
    }
}

.navbar-toggler {
    top: 1.5rem;
}

.megamenu-main-nav {
    .nav-item {
        padding-top: 0.75rem;

        @include media-breakpoint-up(lg) {
            &:not(:last-child) {
                margin-right: .95rem;
            }
        }

        @include media-breakpoint-up(xl) {
            &:not(:last-child) {
                margin-right: 1.425rem;
            }
        }

        .nav-link {
            border-bottom: 1px solid $white;
            color: $unipol-dark-blue;
            font-weight: $font-weight-semibold;
            margin-bottom: 0.75rem;
            padding: 0;

            &:not(.dropdown-toggle) {
                padding-bottom: 0.325rem;
                padding-top: 0.45rem;
            }

            &:hover {
                border-bottom: 1px solid $unipol-dark-blue;
            }
        }

        @include media-breakpoint-down(md) {
            .dropdown-toggle {
                .icon-fix {
                    width: 90%;
                }
            }

            .nav-link {
                &:not(.dropdown-toggle) {
                    padding-top: 0.275rem;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .nav-item:not(:last-child) {
            border-bottom: 1px solid $unipol-off-white;
        }
    }
}

.map-body {
    .megamenu-main-nav {
        .nav-item {
            padding-top: 0.25rem;

            .nav-link {
                margin-bottom: 0.25rem;
            }
        }
    }
}

.dropdown-toggle {
    &:hover {
        text-decoration: none;
    }
}

.dropdown-menu {
    background-color: $white;
    display: none;
    left: 0;
    width: 100%;
    /*transition: all .5s ease;*/
    z-index: $zindex-dropdown;


    &.show {
        display: block;
    }

    li {
        padding-bottom: 0.5rem;
        text-align: left;

        a {
            color: $unipol-green;
            display: block;
            font-weight: $font-weight-semibold;

            .icon-fix {
                max-width: 90%;
            }

            &:hover {
                color: $unipol-dark-blue;
                text-decoration: none;
            }
        }

        p {
            color: $primary;
        }
    }
}

.megamenu {
    .dropdown-menu {
        min-height: rem-calc(300);
    }
}

@include media-breakpoint-up(lg) {
    .megamenu {
        .dropdown-menu {
            background-color: $unipol-off-white;
            @include gradientBorder(top);

            li {
                padding-right: rem-calc(48);
                padding-bottom: 0;
                padding-top: 1.5rem;
            }
        }
    }
}

.navbar:not(.megamenu) {

    .dropdown {
        position: relative;
        margin-bottom: 0 !important;
    }

    .nav-link {
        padding: 0;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 16rem;
        padding: .5rem 0;
        margin: .125rem 0 0;
        font-size: 1rem;
        text-align: left;
        list-style: none;
        background-color: $white;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, .15);


        &.show {
            display: block;
        }

        &-right {
            left: auto;
            right: 0;
        }
    }

    .dropdown-item {
        display: block;
        width: 100%;
        padding: .5rem 1.5rem;
        clear: both;
        font-weight: 400;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border-bottom: 1px solid $unipol-off-white;
    }
}

.filter-bar {
    a {
        color: $primary;
    }

    .dropdown-menu {
        box-shadow: $body-bg -1px 1px 5px;
        margin-left: -.9rem;
        margin-top: .7rem;
        padding: 1rem;

        li {
            padding-bottom: 0.5rem;
            text-align: left;

            a {
                color: $primary;
                display: block;
                font-weight: $font-weight-semibold;
                border-bottom: 1px solid $unipol-off-white;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.dropdown.show {
    .dropdown-toggle .material-icons {
        transform: rotateX(180deg);
    }
}

.accordion-heading {
    .material-icons {
        transform: rotateX(180deg);
    }

    .collapsed {
        .material-icons {
            transform: rotateX(0deg);
        }
    }
}